<template>
  <travio-center-container grid-width="1/2" :pageTitle="'Supplier Details - ' + form.supplierName ">
    <vx-card>
      <vs-tabs class="tabs-shadow-none">
        <vs-tab icon-pack="feather" icon="icon-settings" label="General Info">

          <vs-input class="w-full mt-4" label="Supplier Name*" v-model="form.supplierName" disabled />
          <div class="mt-4">
            <label class="text-sm">Product Type*</label>
            <v-select :value="selectedProductType" append-to-body :options="productTypeOptions" disabled />
          </div>
          <div class="mt-4">
            <label class="text-sm">API Type*</label>
            <v-select :value="selectedApiType" append-to-body :options="apiTypeOptions" disabled />
          </div>
          <vs-input class="w-full mt-4" label="Endpoint URL" v-model="form.endpointUrl" disabled />
          <vs-input class="w-full mt-4" label="Docs URL" v-model="form.docsUrl" disabled />
          <vs-input class="w-full mt-4" label="Status URL" v-model="form.statusUrl" disabled />
        </vs-tab>
        
        <vs-tab icon-pack="feather" icon="icon-users" label="Contacts">
          <vs-input class="w-full mt-4" label="Tech Contact" v-model="form.techContact" disabled />
          <vs-input class="w-full mt-4" label="Account Contact" v-model="form.accountContact" disabled />
          <vs-input class="w-full mt-4" label="Post Book Contact" v-model="form.postBookContact" disabled />
        </vs-tab>

        <vs-tab icon-pack="feather" icon="icon-lock" label="Credentials">
          <vs-input class="w-full mt-4" label="Username" v-model="form.username" disabled />
          <vs-input class="w-full mt-4" type="password" label="Password" v-model="form.password" disabled />
        </vs-tab>
        
        <vs-tab icon-pack="feather" icon="icon-code" label="Settings">
          <supplier-settings :supplierId="supplierId" />
        </vs-tab>
      </vs-tabs>

      <div class="flex flex-wrap justify-end">
        <vs-button @click="handleCancel" type="border" color="danger" class="mt-4 mr-4">Cancel</vs-button>
      </div>

    </vx-card>
  </travio-center-container>
</template>

<script>
import { Form } from '@/core-modules/form-framework/Form.js'
import SupplierSettings from './SupplierSettings.vue'
import { v4 as uuidv4 } from 'uuid';

export default {
  components: {
    SupplierSettings
  },
  props: { 
    supplierId: { required: true }
  },
  data () {
    return {
      form: new Form({
        supplierName: '',
        productTypeId: 0,
        apiTypeId: 0,
        
        endpointUrl: null,
        docsUrl: null,
        statusUrl: null,

        username: null,
        password: null,

        techContact: null,
        accountContact: null,
        postBookContact: null,

      }),
      selectedProductType: null,
      selectedApiType: null,
      productTypeOptions: [],
      apiTypeOptions: [],
    }
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    }
  },
  mounted () {
    this.$http.get(`api/admin/suppliers/lookups`)
    .then(response => {
      this.productTypeOptions = response.data.productTypeOptions
      this.apiTypeOptions = response.data.apiTypeOptions

      this.$http.get(`api/admin/suppliers/${this.supplierId}`)
      .then(response => {
        this.form = new Form(Object.assign(this.form.data(), response.data))
        this.selectedProductType = this.productTypeOptions.find(x => x.code == response.data.productTypeId) || null
        this.selectedApiType = this.apiTypeOptions.find(x => x.code == response.data.apiTypeId) || {};
      })
      .catch(err => console.error(err) )
      
    })
    .catch(err => console.error(err) )
  },
  beforeDestroy () {
  },
  methods: {
    handleCancel () {
      this.$router.push({ name: 'admin-supplier' })
    }
  }
}
</script>

<style>

  /* Fix date picker issue month selection partially hidden*/
  .flatpickr-months .flatpickr-month {
    overflow: initial;
  }

  .fixed-height-supplier-edit {
    height: calc(var(--vh, 1vh) * 100 - 38rem);
    /* overflow-y: hidden; */
    overflow-x: hidden;
    position: relative;
  }
</style>